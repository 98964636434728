import officerImage from '../../assets/imgs/management/cm.png';

export default function CMBio (props) {

    return (
        <div className="bio-container">
            <div className="bio-card">
                <div className="bio-image">
                <img src={officerImage} alt="Officer Name" />
                </div>
                <div className="bio-content">
                    <h2>Daniel Mwangi Muriuki</h2>
                    <h3>Commercial Manager</h3>
                    <hr />
                    <div className="bio-details">
                        <p>B.Com Finance - KU; CPA(K);</p>
                    </div>
                    <p>
                        Daniel Mwangi Muriuki is a Certified Public Accountant of Kenya (CPAK) and holds a Bachelor of Commerce 
                        (Finance) degree. He has over 15 years of experience in accounting, investment, and business development.
                    </p>
                    <p>
                        As the Commercial Manager, Daniel oversees the customer relations, business development, and billing 
                        departments, driving the organization’s growth strategy through effective leadership and strategic planning. 
                        His role involves fostering strong client relationships, exploring new market opportunities, and ensuring 
                        efficient billing processes to enhance the company’s financial performance.
                    </p>
                    <p>
                        Previously, he served as a Senior Accountant at one of Kenya's leading insurance brokerage firms, where 
                        he demonstrated exceptional financial management skills, improved operational efficiency, and contributed 
                        to the company’s profitability.
                    </p>
                    <p>
                        Daniel brings a wealth of expertise, leadership, and a results-driven approach that will be instrumental 
                        in achieving the organization’s long-term commercial objectives.
                    </p>
                    <hr />
                </div>
            </div>
        </div>     
    );

    
}