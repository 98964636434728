import { useEffect, useState } from "react";
import "../../styles/staff/viewcareers.scss";
import Header from "../../components/header";
import Footer from "../../components/footer";
import MenuTab from "../../components/menu_tab";
import Banner from "../../components/banner";
import Button from "../../components/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faX, faIns, faLocation, faLocationPin, faPhone, faUserCheck, faWater, faPlug, faTools, faHeadset, faTachometer, faTachometerAlt, faFaucet, faFaucetDrip, faSync } from '@fortawesome/free-solid-svg-icons';
import PostItem from "../../components/post_item";
import allstaffmeeting from "../../assets/imgs/staffmeeting.jpeg"
import StaffHeader from "../../components/staff/staffheader";
import StaffFooter from "../../components/staff/stafffooter";
import CareersTable from "../../components/staff/careerstable";

export default function ViewCareers (props) {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    fetch("/api/careers")
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        console.log(data);
        setData(data)
      })
      .catch((e) => {});
  }, []);
  
  return (
    <div className="body">
      <Banner />
      <StaffHeader toggleMenu={toggleMenu} 
        setToggleMenu={setToggleMenu}
      />
      {toggleMenu && <MenuTab toggleMenu={toggleMenu} 
        setToggleMenu={setToggleMenu}
      />}
      <div className="careers" id="careers">
        <div className="centerheading">
          <h2 className="decorated-heading">Career Opportunities</h2>
        </div>
        {data?.length > 0 ? <CareersTable data={data} /> : <div className="centertext">
          <div className="centerheading">
            <h3 className="heading">No Jobs Available</h3>
          </div>
          <p>At the moment, there are no job openings. <a href="/admin/postcareer">Click here to post a new job</a></p>
          <p>Thank you for your continued efforts in making Githunguri Water and Sanitation the number 1 Water Service Provider!</p>
        </div>}
      </div>
      <Footer />
    </div>
  );
}