import { useEffect, useState } from "react";
import '../styles/officerbio.scss';
import Banner from '../components/banner';
import Header from '../components/header';
import Footer from '../components/footer';
import MDBio from '../components/bios/mdBio';
import GISBio from '../components/bios/gisBio';
import MenuTab from '../components/menu_tab';
import CMBio from "../components/bios/cmBio";

const OfficerBio = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  const officer = window.location.pathname.split("/")[3]

  return (
    <div>
      <Banner />
      <Header toggleMenu={toggleMenu} 
        setToggleMenu={setToggleMenu}
      />
      {toggleMenu && <MenuTab toggleMenu={toggleMenu} 
        setToggleMenu={setToggleMenu}
      />}
      <div className="image">
        <div>
          <p>Our Management Team</p>
        </div>
      </div>
      {officer === "md" ? <MDBio /> : officer === "cm" ? <CMBio /> : <GISBio />}
      <Footer />
    </div>
  );
};

export default OfficerBio;
